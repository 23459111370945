import { trimObjectValues } from 'agilite-utils'

import MemoryStore from '../utils/memory-store'
import { cleanupObject, isDuplicateEntry, updateArrayDataFromSave, cleanUpTableData } from '../utils/utilities'
import { postData } from '../core/core-utils'
import { formatRecord } from './connectors-utils'
import Enums from '../utils/enums'

/*
  ENUMS
*/
export const actions = {
  RESET_STATE: 'RESET_STATE_CONNECTORS',
  CREATE_PROFILE: 'CREATE_PROFILE_CONNECTORS',
  SAVE_PROFILE: 'SAVE_PROFILE_CONNECTORS',
  CANCEL_PROFILE: 'CANCEL_PROFILE_CONNECTORS',
  EDIT_PROFILE: 'EDIT_PROFILE_CONNECTORS',
  DELETE_PROFILE: 'DELETE_PROFILE_CONNECTORS',
  FETCH_ALL_DATA: 'FETCH_ALL_DATA_CONNECTORS',
  SET_SEARCH_FILTER: 'SET_SEARCH_FILTER_CONNECTORS',
  UPDATE_DATA: 'UPDATE_DATA_CONNECTORS'
}

/*
  DISPATCH FUNCTIONS
*/
export const setSearchFilter = value => {
  return {
    type: actions.SET_SEARCH_FILTER,
    value
  }
}

export const updateData = data => {
  return {
    type: actions.UPDATE_DATA,
    data
  }
}

export const saveProfile = (appId, recordId, state, callback) => {
  return async dispatch => {
    const entry = MemoryStore.activeEntries[recordId]
    const headers = {}
    let result = true
    let reqType = ''
    let tempArray = state.data.concat()
    let resetService = false
    let errMsg = null

    // Trim & Cleanup
    cleanupObject(entry.data)
    await trimObjectValues(entry.data)

    // Check if profile key is valid
    if (entry.data.key === '') {
      errMsg = 'Please provide a valid Profile Key'
      return callback(errMsg)
    } else if (entry.data.name === '') {
      errMsg = 'Please provide a valid Connection Name'
      return callback(errMsg)
    }

    // Duplicate Check
    result = isDuplicateEntry(entry, 'key', tempArray, true)

    if (!result) {
      errMsg = `Profile Key '${entry.data.key}' is already being used`
      return callback(errMsg)
    }

    // Finalize
    switch (entry.data.connectionType) {
      case '1': // Web API
        switch (entry.data.connectionData.authType) {
          case 'basic':
            if (!entry.data.connectionData.username || entry.data.connectionData.username.trim() === '') {
              errMsg = 'Please provide a Username in API Configuration'
            } else if (!entry.data.connectionData.password || entry.data.connectionData.password.trim() === '') {
              errMsg = 'Please provide a Password in API Configuration'
            }

            break
          case Enums.VALUES_STRINGS.MS_GRAPH_BEARER:
            if (entry.custom.credentialsReset) {
              if (!entry.data.connectionData.tenant || entry.data.connectionData.tenant.trim() === '') {
                errMsg = 'Please provide a Tenant in API Configuration'
              } else if (!entry.data.connectionData.clientId || entry.data.connectionData.clientId.trim() === '') {
                errMsg = 'Please provide a Client ID in API Configuration'
              } else if (!entry.data.connectionData.clientSecret || entry.data.connectionData.clientSecret.trim() === '') {
                errMsg = 'Please provide a Client Secret in API Configuration'
              }
            }

            break
          default:
        }

        if (errMsg) {
          return callback(errMsg)
        }

        if (entry.data.connectionData.authType !== Enums.VALUES_STRINGS.MS_GRAPH_BEARER) {
          if (!entry.data.connectionData.url || entry.data.connectionData.url.trim() === '') {
            errMsg = 'Please provide a URL in API Configuration'
            return callback(errMsg)
          }
        }

        if (entry.data.connectionData.headerParams) {
          for (let x = 0; x < entry.data.connectionData.headerParams.length; x++) {
            if (!entry.data.connectionData.headerParams[x].paramKey && entry.data.connectionData.headerParams[x].paramValue) {
              errMsg = 'Please provide a key or key and value for the Header Parameters'
              return callback(errMsg)
            }
          }
        }

        if (entry.data.connectionData.queryParams) {
          for (let x = 0; x < entry.data.connectionData.queryParams.length; x++) {
            if (!entry.data.connectionData.queryParams[x].paramKey && entry.data.connectionData.queryParams[x].paramValue) {
              errMsg = 'Please provide a key or key and value for the URL Parameters'
              return callback(errMsg)
            }
          }
        }

        if (entry.data.connectionData.headerParams)
          entry.data.connectionData.headerParams = cleanUpTableData(entry.data.connectionData.headerParams, true)
        if (entry.data.connectionData.queryParams)
          entry.data.connectionData.queryParams = cleanUpTableData(entry.data.connectionData.queryParams, true)

        break
      case '7': // DB2 (iSeries)
        if (!entry.data.connectionData.host) {
          errMsg = 'Please provide a Host in API Configuration'
          return callback(errMsg)
        }

        break
      case '6': // SMTP
        if (!entry.data.connectionData.smtpHost) {
          errMsg = 'Please provide a SMTP Host in API Configuration'
          return callback(errMsg)
        }

        break
      case '10': // MongoDB
        if (!entry.data.connectionData.collectionName) {
          errMsg = 'Please provide a Collection Name in API Configuration'
        } else if (!entry.data.connectionData.schema) {
          errMsg = 'Please provide a Schema Spec in API Configuration'
        } else if (entry.data.connectionData.connectionType && entry.data.connectionData.connectionType === '2') {
          if (!entry.data.connectionData.dbName) {
            errMsg = 'Please provide a Database Name in API Configuration'
          } else if (!entry.data.connectionData.port) {
            errMsg = 'Please provide a Port in API Configuration'
          } else if (!entry.data.connectionData.host) {
            errMsg = 'Please provide a Host Address in API Configuration'
          }
        }

        if (errMsg) {
          return callback(errMsg)
        }

        break
      case '12': // MS SQL
        if (!entry.data.connectionData.server || entry.data.connectionData.server === '') {
          errMsg = 'Please provide a Server in API Configuration'
          return callback(errMsg)
        }

        break
      case '13': // LDAP
        if (!entry.data.connectionData.url || entry.data.connectionData.url === '') {
          errMsg = 'Please provide a LDAP URL in API Configuration'
        }

        if (errMsg) {
          return callback(errMsg)
        }

        break
      case '14': // PostgreSQL
        if (!entry.data.connectionData.user || entry.data.connectionData.user === '') {
          errMsg = 'Please provide a User in API Configuration'
        } else if (!entry.data.connectionData.host || entry.data.connectionData.host === '') {
          errMsg = 'Please provide a Host in API Configuration'
        } else if (!entry.data.connectionData.database || entry.data.connectionData.database === '') {
          errMsg = 'Please provide a Database in API Configuration'
        } else if (!entry.data.connectionData.port || entry.data.connectionData.port === '') {
          errMsg = 'Please provide a Port in API Configuration'
        }

        if (errMsg) {
          return callback(errMsg)
        }

        break
      case '15': // OracleDb
        if (!entry.data.connectionData.user || entry.data.connectionData.user === '') {
          errMsg = 'Please provide a User in API Configuration'
        } else if (!entry.data.connectionData.connectionString || entry.data.connectionData.connectionString === '') {
          errMsg = 'Please provide a Connection String in API Configuration'
        }

        if (errMsg) {
          return callback(errMsg)
        }

        break
      case '16': // SFTP
        if (!entry.data.connectionData.username) {
          errMsg = 'Please provide a Username in API Configuration'
          return callback(errMsg)
        } else if (!entry.data.connectionData.password) {
          errMsg = 'Please provide a Password in API Configuration'
          return callback(errMsg)
        }
        break
      case '17': // IMAP
        if (!entry.data.connectionData.username) {
          errMsg = 'Please provide a Username in API Configuration'
          return callback(errMsg)
        } else if (!entry.data.connectionData.password) {
          errMsg = 'Please provide a Password in API Configuration'
          return callback(errMsg)
        }
        break
      case '18': // Snowflake
        if (!entry.data.connectionData.account) {
          errMsg = 'Please provide an Account in API Configuration'
          return callback(errMsg)
        } else if (!entry.data.connectionData.username) {
          errMsg = 'Please provide a Username in API Configuration'
          return callback(errMsg)
        } else if (!entry.data.connectionData.password) {
          errMsg = 'Please provide a Password in API Configuration'
          return callback(errMsg)
        }
        break
      default:
    }

    // Post Data to Agilit-e
    tempArray = formatRecord(entry, tempArray)
    reqType = entry.custom.action === 'create' ? 'post' : 'put'

    // Check if any Services need to be reset
    if (reqType === 'put') {
      switch (entry.data.connectionType) {
        case '6': // SMTP
        case '7': // DB2 (iSeries)
        case '10': // MongoDB
        case '11': // FTP
        case '12': // MS SQL
        case '13': // LDAP
        case '14': // PostgreSQL
        case '15': // OracleDB
        case '16': // SFTP
        case '17': // IMAP
        case '18': // Snowflake
          resetService = true
          break
        default:
      }
    }

    // Create Header Object
    headers['record-id'] = recordId
    headers['reset-service'] = resetService

    postData(appId, reqType, entry, headers)
      .then(response => {
        callback(null)

        entry.custom.action = ''
        entry.custom.isSavedDoc = true
        entry.key = response.data._id
        entry._id = response.data._id
        entry.data.isActiveDescription = response.data.data.isActive ? 'Yes' : 'No'

        // Microsoft Graph Bearer
        if (entry.data.connectionData.authType === Enums.VALUES_STRINGS.MS_GRAPH_BEARER) {
          entry.data.connectionData.clientId = ''
          entry.data.connectionData.clientSecret = ''
        }

        // Add Connection Data and Route Data if relevant
        if (!entry.data.connectionData) {
          entry.data.connectionData = {}
        }

        for (const x in entry.data.routes) {
          if (!entry.data.routes[x].routeData) {
            entry.data.routes[x].routeData = {}
          }
        }

        // Dispatch New Array
        tempArray = updateArrayDataFromSave(entry, tempArray)
        dispatch({ type: actions.UPDATE_DATA, data: tempArray })
      })
      .catch(error => {
        console.log(error)

        if (error.response) {
          callback(error.response.data.errorMessage)
        } else {
          callback(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      })
  }
}
