import React from 'react'
import moment from 'moment'
import { message, Form, Button, Col, Row, Card, Switch, Select, Input, Modal, Tabs, Menu, Popconfirm, Dropdown } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import GroupNameContainer from '../../reusables/containers/group-name-container'
import SolutionsContainer from '../../reusables/containers/solutions-container'
import TooltipIcon from '../../reusables/components/tooltip-icon'
import EnumsTooltips from '../../utils/enums-tooltips'
import Description from '../../reusables/components/description'
import Notes from '../../reusables/components/notes'
import Enums from '../../utils/enums'
import Theme from '../../utils/agilite-theme'
import MemoryStore from '../../utils/memory-store'

import BatchLoggingLogView from './batch-logging-log-view'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { resetLogs, getLogs } from '../batch-logging-utils'

class BatchLoggingForm extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[props.tabKey]
    this.data = []

    this.state = {
      isNewDoc: this.entry.custom.isNewDoc,
      isLoading: false,
      logViewData: []
    }

    // Setup Event Binding
    this.handleGetLogs = this.handleGetLogs.bind(this)
    this.onFieldChange = this.onFieldChange.bind(this)
    this.saveProfile = this.saveProfile.bind(this)
    this.cancelProfile = this.cancelProfile.bind(this)
  }

  async handleGetLogs (date, includeResolvedLogs) {
    let data = []

    try {
      data = await getLogs(this.entry.data.key, date, includeResolvedLogs)
      this.setState({ logViewData: data })
      return data
    } catch (err) {
      message.error(err)
    }
  }

  onFieldChange (key, value) {
    this.entry.custom.isModified = true
    this.entry.data[key] = value
  }

  cancelProfileConfirm () {
    if (this.entry.custom.isModified) {
      Modal.confirm({
        onOk: () => this.cancelProfile(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      this.cancelProfile()
    }
  }

  cancelProfile () {
    this.props.closeTab(this.props.tabKey, this.props.tabKey, this.props.tabs)
  }

  saveProfile () {
    const tmpThis = this

    tmpThis.setState({
      isLoading: true
    })

    // Reset isModified and isModifiedSubform properties
    this.entry.custom.isModified = false
    this.entry.custom.isModifiedSubform = false

    tmpThis.props.saveProfile(tmpThis.props.appId, tmpThis.props.tabKey, this.props.state, (err) => {
      tmpThis.setState({
        isLoading: false
      })

      if (err) {
        message.error(err)
      } else {
        tmpThis.props.closeTab(tmpThis.props.tabKey, tmpThis.props.tabKey, tmpThis.props.tabs)
      }
    })
  }

  render () {
    const menu = (
      <Menu>
        <Menu.Item key='0'>
          <Popconfirm
            title='Are you sure you want to reset all Logs for this Profile?'
            onConfirm={async () => {
              try {
                await resetLogs(this.entry.data.key)
                await this.handleGetLogs([moment().startOf('month'), moment()], false)
                message.success(`Logs for Batch Logging Profile: '${this.entry.data.key}' successfully reset`)
              } catch (e) {
                message.error(e)
              }
            }}
            okText='Yes'
            cancelText='No'
          >
            <span style={{ padding: '10 0' }}>Reset Logs</span>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    )

    return (
      <Row type='flex' justify='center'>
        <Col span={23}>
          <Card
            title={this.props.title}
            style={{ textAlign: 'left' }}
            type='inner'
            extra={!this.state.isNewDoc && MemoryStore.userProfile.teamPrivileges.batchlogging !== 'Reader' ?
              <Dropdown overlay={menu} trigger={['click']}>
                <FontAwesomeIcon icon={faEllipsisV} style={{ padding: 5, fontSize: 17, color: '#B4483E', cursor: 'pointer' }} />
              </Dropdown>
              : null}
          >
            <Tabs>
              <Tabs.TabPane tab='General' key='general'>
                <Form
                  autoComplete='off'
                >
                  <Row type='flex' justify='space-between'>
                    <Col xs={24} lg={11}>
                      <Form.Item>
                        <Switch
                          disabled={MemoryStore.userProfile.teamPrivileges.batchlogging === 'Reader'}
                          checkedChildren='Active'
                          unCheckedChildren='Inactive'
                          defaultChecked={this.entry.data.isActive}
                          onChange={(e) => {
                            this.onFieldChange('isActive', e)
                          }}
                        />
                      </Form.Item>
                      <Form.Item>
                        <span style={{ color: 'red' }}>* </span>{'Profile Key '}
                        <TooltipIcon title={EnumsTooltips.general.profileKey} />
                        <Input
                          name='key'
                          placeholder='Provide a unique Profile Key'
                          disabled={!this.state.isNewDoc || MemoryStore.userProfile.teamPrivileges.batchlogging === 'Reader'}
                          defaultValue={this.entry.data.key}
                          onChange={(e) => {
                            this.onFieldChange('key', e.target.value)
                          }}
                          required
                        />
                      </Form.Item>
                      <Form.Item>
                        <span style={{ color: 'red' }}>* </span>{'Profile Name '}
                        <TooltipIcon title={EnumsTooltips.general.profileName} />
                        <Input
                          name='name'
                          placeholder='Provide a Profile Name'
                          disabled={MemoryStore.userProfile.teamPrivileges.batchlogging === 'Reader'}
                          defaultValue={this.entry.data.name}
                          onChange={(e) => {
                            this.onFieldChange('name', e.target.value)
                          }}
                          required
                        />
                      </Form.Item>
                      <Description
                        disabled={MemoryStore.userProfile.teamPrivileges.batchlogging === 'Reader'}
                        description={this.entry.data.description}
                        onChange={(e) => {
                          this.onFieldChange('description', e.target.value)
                        }}
                      />
                      <Notes
                        notes={this.entry.data.notes}
                        onChange={e => {
                          this.onFieldChange('notes', e.target.value)
                        }}
                      />
                      <GroupNameContainer
                        disabled={MemoryStore.userProfile.teamPrivileges.batchlogging === 'Reader'}
                        defaultValue={this.entry.data.groupName}
                        onValueChange={(value) => {
                          this.onFieldChange('groupName', value)
                        }}
                        onValueSelect={(value) => {
                          this.onFieldChange('groupName', value)
                        }}
                      />
                      <SolutionsContainer
                        disabled={MemoryStore.userProfile.teamPrivileges.batchlogging === 'Reader'}
                        defaultValue={this.entry.data.solutions}
                        onValueChange={(value) => {
                          this.onFieldChange('solutions', value)
                        }}
                      />
                      <Form.Item>
                        Log Level
                        <Select
                          defaultValue={this.entry.data.logLevel}
                          onChange={value => this.onFieldChange('logLevel', value)}
                        >
                          <Select.Option value='basic'>Basic</Select.Option>
                          <Select.Option value='advanced'>Advanced</Select.Option>
                          <Select.Option value='verbose'>Verbose</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 20 }}>
                    {MemoryStore.userProfile.teamPrivileges.batchlogging !== 'Reader' ?
                      <Button
                        name='submit'
                        style={{ backgroundColor: Theme.successColor, color: Theme.white, width: 80, marginRight: 10, float: 'left' }}
                        onClick={() => this.saveProfile()}
                        disabled={this.state.isLoading}
                      >
                        {this.state.isLoading ? <LoadingOutlined spin /> : <span>Submit</span>}
                      </Button>
                      : null}
                    <Button
                      name='cancel'
                      label='Cancel'
                      disabled={this.state.isLoading}
                      onClick={e => {
                        e.preventDefault()
                        this.cancelProfileConfirm()
                      }}
                      style={{ backgroundColor: Theme.dangerColor, color: Theme.white, width: 'auto', marginRight: 10, float: 'left' }}
                    >
                      Cancel
                    </Button>
                  </Row>
                </Form>
              </Tabs.TabPane>
              <Tabs.TabPane tab='Logs' key='logs'>
                <Card>
                  <BatchLoggingLogView
                    // eslint-disable-next-line
                    handleGetLogs={this.handleGetLogs}
                    logViewData={this.state.logViewData}
                    isNewDoc={this.state.isNewDoc}
                    logProfileKey={this.entry.data.key}
                  />
                </Card>
              </Tabs.TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default BatchLoggingForm
